<template>
  <div class="report">
    <div class="page-header">
      <h1>{{ $t('Report') }} > {{$t('Sea Food Product Report') }} </h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Sea Food Product Report') }}</h2>
      </div>

      <div class="charts-cards">
        <div class="chart-inner">
          <TotalSales />
        </div>
      </div>

      <div class="product-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Photo') }}</th>
              <th scope="col">{{ $t('Regular Price') }}</th>
              <th scope="col">{{ $t('Product Name') }}</th>
              <th scope="col">{{ $t('Product Categories') }}</th>
              <th scope="col">{{ $t('Sales') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products">
              <td class="product-image-td">
                <div class="product-image-wrapper">
                  <img class="product-image img-fluid" v-if="getProductImage(product.product_images)" :src="getProductImage(product.product_images)" />
                  <img class="product-image img-fluid" v-else src="@/assets/no-image.jpg" />
                </div>
              </td>
              <td>${{ product.product_regular_price }}</td>
              <td v-if="product.product_quantity || product.product_quantity === 0 ">{{ product.product_quantity }}</td>
              <td v-if="langcode === 'zh'">{{ product.product_name_zh }}</td>
              <td v-else>{{ product.product_name_en }}</td>
              <td>{{ getProductCategoires(product) }}</td>
              <td>15</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Product from '@/lib/product';
import TotalSales from '@/components/dashboard/TotalSales.vue';

export default {
  name: 'PerProductReport',
  components: {
    TotalSales,
  },
  mounted(){
    this.loadProducts();
  },
  methods:{
    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    getProductCategoires(product){
      const productCategories = [];
      for(let i = 0; i < product.product_categories.length; i++){
        const productCategorie = product.product_categories[i];
        if(productCategorie){
          if(this.langcode === 'zh'){
            productCategories.push(productCategorie.categorie_name_zh);
          }else{
            productCategories.push(productCategorie.categorie_name_en);
          }
        }
      }
      const productCategoriesString = productCategories.join(', ');
      if(!productCategoriesString){
        return '';
      }
      return productCategoriesString
    },
    async loadProducts(){
      try{
        const loginInfo = Common.getLoginInfo();
        const products = await Product.loadAllProducts(this.apiUrl, loginInfo);
        this.orignalProducts = products;
        this.products = products;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      products: [],
      searchProducts: null, //Keep the tab result
      orignalProducts: [],
    }
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.charts-cards{
  padding-left: 20px;
  margin-bottom: 30px;
}
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.categories-filter{
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  .filter-label{
    margin-right: 20px;
  }
}
.product-image-wrapper{
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  .product-image{
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.product-image-td{
  width: 20%;
}
</style>
